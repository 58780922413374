<template>
    <div class="activies">
        <div class="list-container">
            <div class="list" v-if="list.length">
                <div
                    class="item"
                    v-for="(item, idx) in list"
                    :key="item._id"
                    @click="goTo(item)"
                >
                    <div class="img-container">
                        <img
                            class="item-img"
                            alt=""
                            v-real-img="item.cover.src"
                        />
                        <Icon
                            class="video-play-icon"
                            type="logo-youtube"
                            v-if="item.video"
                        />
                    </div>
                    <div class="item-right">
                        <div class="item-title">{{ item.title }}</div>
                        <div class="duration">
                            {{ item.duration }}{{ "课时" | language }}
                        </div>
                        <div class="item-content">
                            <div class="item-content-title">
                                {{ "指导教师" | language }}：{{ item.tutor }}
                            </div>
                            <span>{{ "产出方向" | language }}：</span>
                            <span
                                class="item-content-time"
                                v-for="(y, i) in item.outputs"
                                :key="i"
                                >{{ y }} &nbsp;</span
                            >
                        </div>
                    </div>
                    <!-- <div class="item-desc row3-ellipse">{{item.content}}</div> -->
                </div>
            </div>
            <div class="no-date" :class="{ 'show-no-data': isEmpty }" v-else>
                {{ "没有符合筛选条件的数据" | language }}~
            </div>
            <!-- <div class="flex"></div> -->
        </div>
        <Page
            :total="total"
            :current="pageNum"
            :page-size="pageSize"
            @on-change="handleChange"
            style="margin: 10px 0 100px 0; color: rgba(0, 0, 0, 0.3)"
            show-total
        >
            <span class="ivu-page-total" style="font-size: 16px"
                >{{ "共有" | language }} {{ total }} {{ "条" | language }}</span
            >
        </Page>
    </div>
</template>

<script>
import CommonSearchBar from "../../compontents/information/CommonETalkBar.vue";
export default {
    components: {
        CommonSearchBar,
    },
    data() {
        return {
            list: [],
            pageSize: 10,
            pageNum: 1,
            total: 0,
            isEmpty:false
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            let res = await this.$http.get(
                `/information/course?pageSize=${this.pageSize}&pageNum=${
                    this.pageNum - 1
                }`
            );
            this.list = res.result;
            this.total = res.count;
            if(res.result.length == 0){
                this.isEmpty = true;
            }
        },
        goTo(item) {
            this.$router.push(`/base/courses/detail/${item._id}`);
        },
        //分页
        handleChange(page) {
            this.isEmpty = false;
            this.pageNum = page;
            this.fetchData();
        },
    },
};
</script>

<style lang="less" scoped>
.activies {
    // display: flex;
    // flex-wrap: wrap;
    .list-container {
        display: flex;

        // flex-direction: column;
        min-height: 1000px;
        .list {
            display: flex;
            flex-wrap: wrap;
            // align-items: center;
            justify-content: space-between;
            align-items: flex-start;
            align-content: flex-start;
            .item {
                cursor: pointer;
                flex: 1;
                min-width: 400px;
                margin: 0 30px 30px 0;
                padding: 22px 20px 27px 20px;
                height: 236px;
                background: rgba(255, 255, 255, 1);
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.14);
                display: flex;
                .img-container {
                    width: 150px;
                    height: 150px;
                    border-radius: 2px;
                    overflow: hidden;
                    position: relative;
                    flex-shrink: 1;
                    .item-img {
                        width: 100%;
                        height: 100%;
                        border-radius: 2px;
                        object-fit: cover;
                        transition: all ease 0.3s;
                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }
                .item-right {
                    margin-left: 20px;
                    flex: 1;
                }
                .item-title {
                    margin-bottom: 10px;
                    text-align: left;
                    font-size: 18px;
                    font-weight: 600;
                    color: rgba(0, 0, 0, 0.9);
                    line-height: 27px;
                }
                .duration {
                    text-align: left;
                    font-size: 16px;
                    margin-bottom: 5px;
                    color: #f55e5a;
                }
                .item-content {
                    text-align: left;
                    opacity: 0.6;
                    font-size: 16px;
                    color: #000000;
                    line-height: 24px;
                }
                .item-desc {
                    margin-bottom: 20px;
                    text-align: left;
                    height: 66px;
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.85);
                    line-height: 22px;
                }
                .btn-group {
                    display: flex;
                    justify-content: flex-start;
                }
            }
            .item:first-child {
                margin-top: 0;
            }
        }
        .no-date {
            width: 200px;
            height: 100px;
            line-height: 100px;
            margin: 60px auto;
            text-align: center;
            font-size: 30px;
            font-weight: bold;
            visibility:hidden;
            opacity:0;
        }
        .flex {
            flex: 1;
        }
    }
}
@media screen and (max-width: 960px) {
    .activies {
        .list-container {
            .list {
                .item {
                    min-width: 100%;
                    .img-container {
                        width: 100px;
                        height: 100px;
                    }
                }
            }
        }
    }
}
</style>
<style>
.show-no-data {
    visibility: visible !important;
    opacity: 1 !important;
}
</style>